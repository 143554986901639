<template>
  <b-col>
    <!-- form -->
    <validation-observer ref="familyDataForm">
      <b-form @submit.prevent="validationForm">
        <b-row>
          <b-col sm="12">
            <b-card
              v-for="( familyMember, index ) in familyMembers"
              :key="familyMember+index"
              ref="row"
              :familiar="familyMember+index"
              :style="{height: trHeight}"
              class="p-2"
            >
              <b-row
                style="justify-content: flex-end;"
              >
                <!-- Header Familiar Member -->
                <b-col
                  style="text-align: center"
                  lg="12"
                >
                  <h4 class="mt-1 mb-3">
                    DATOS DEL FAMILIAR N° {{ index+1 }}
                  </h4>
                </b-col>
                <!-- paternal lastname Family Member -->
                <b-col md="3">
                  <b-form-group
                    label="Apellido paterno"
                    :label-for="`${index}familiarPLastname`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Apellido Paterno"
                      rules="max:20"
                      :vid="`${index}familiarPLastname`"
                    >
                      <b-form-input
                        :id="`${index}familiarPLastname`"
                        v-model="familyMember.familiarPLastname"
                        :state="errors.length > 0 ? false:null"
                        name="familiarPLastname"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- maternal lastname Family Member-->
                <b-col md="3">
                  <b-form-group
                    label="Apellido materno"
                    :label-for="`${index}familiarMLastname`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Apellido Materno"
                      rules="max:20"
                      :vid="`${index}familiarMLastname`"
                    >
                      <b-form-input
                        :id="`${index}familiarMLastname`"
                        v-model="familyMember.familiarMLastname"
                        :state="errors.length > 0 ? false:null"
                        name="familiarMLastname"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- names Family Member-->
                <b-col md="6">
                  <b-form-group
                    label="Nombres"
                    :label-for="`${index}familiarNames`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nombres"
                      rules="max:100"
                      :vid="`${index}familiarNames`"
                    >
                      <b-form-input
                        :id="`${index}familiarNames`"
                        v-model="familyMember.familiarNames"
                        :state="errors.length > 0 ? false:null"
                        name="familiarNames"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- document type Family Member-->
                <b-col md="4">
                  <b-form-group
                    label="Tipo de documento"
                    :label-for="`${index}familiarDocumentType`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Tipo de documento"
                      :vid="`${index}familiarDocumentType`"
                    >
                      <b-form-select
                        :id="`${index}familiarDocumentType`"
                        v-model="familyMember.familiarDocumentType"
                        :options="optionsTypeDocument"
                        :state="errors.length > 0 ? false:null"
                        name="familiarDocumentType"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- document number Family Member-->
                <b-col md="4">
                  <b-form-group
                    label="Número de documento"
                    :label-for="`${index}familiarNumberDocument`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Número de Documento"
                      :rules="`document:${familyMember.familiarDocumentType}`"
                      :vid="`${index}familiarNumberDocument`"
                    >
                      <b-form-input
                        :id="`${index}familiarNumberDocument`"
                        v-model="familyMember.familiarNumberDocument"
                        :disabled="familyMember.familiarDocumentType ? false:true"
                        :state="errors.length > 0 ? false:null"
                        name="familiarNumberDocument"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- relationship Family Member -->
                <b-col md="4">
                  <b-form-group
                    label="Parentesco"
                    :label-for="`${index}familiarRelationship`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Parentesco"
                      :vid="`${index}familiarRelationship`"
                    >
                      <b-form-select
                        :id="`${index}familiarRelationship`"
                        v-model="familyMember.familiarRelationship"
                        :options="optionsFamiliarRelationship"
                        :state="errors.length > 0 ? false:null"
                        name="familiarRelationship"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- profession Family Member -->
                <b-col md="4">
                  <b-form-group
                    label="Profesión/Oficio"
                    :label-for="`${index}familiarProfession`"
                  >
                    <v-select
                      :id="`${index}familiarProfession`"
                      v-model="familyMember.familiarProfession"
                      label="profesion"
                      :options="optionsProfession"
                      :name="`${index}familiarProfession`"
                    >
                      <span slot="no-options">
                        Lo sentimos, no hay opciones que coincidan.
                      </span>
                    </v-select>
                  </b-form-group>
                </b-col>
                <!-- occupation Family Member -->
                <b-col md="4">
                  <b-form-group
                    label="Ocupación"
                    :label-for="`${index}familiarOccupation`"
                  >
                    <v-select
                      :id="`${index}familiarOccupation`"
                      v-model="familyMember.familiarOccupation"
                      label="ocupacion"
                      :options="optionsActivities"
                      :name="`${index}familiarOccupation`"
                    >
                      <span slot="no-options">
                        Lo sentimos, no hay opciones que coincidan.
                      </span>
                    </v-select>
                  </b-form-group>
                </b-col>
                <!-- born date -->
                <b-col md="4">
                  <b-form-group
                    label="Fecha de nacimiento"
                    :label-for="`${index}familiarBornDate`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Fecha de Nacimiento"
                      :vid="`${index}familiarBornDate`"
                    >
                      <!-- <b-form-datepicker
                        :id="`${index}familiarBornDate`"
                        v-model="familyMember.familiarBornDate"
                        locale="es"
                        :max="maxDate"
                        name="familiarBornDate"
                        :state="errors.length > 0 ? false:null"
                      />
                      <b-form-input
                        v-show="false"
                        v-model="familyMember.familiarBornDate"
                        :state="errors.length > 0 ? false:null"
                      /> -->
                      <flat-pickr
                        :id="`${index}familiarBornDate`"
                        v-model="familyMember.familiarBornDate"
                        placeholder="dd/mm/aaaa"
                        :name="`${index}familiarBornDate`"
                        :state="errors.length > 0 ? false:null"
                        :config="{ ...configDatePickr, maxDate, minDate }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- is dependent -->
                <b-col
                  md="12"
                  class="my-2"
                >
                  <b-form-group
                    label-cols-sm="12"
                    content-cols-sm="12"
                    label="¿El familiar es dependiente?"
                    :label-for="`${index}isDependent`"
                    style="text-align: center"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Es dependiente"
                      :vid="`${index}isDependent`"
                    >
                      <b-form-radio-group
                        :id="`${index}isDependent`"
                        v-model="familyMember.familiarIsDependent"
                        :options="optionDependent"
                        value-field="value"
                        text-field="text"
                        :name="`${index}isDependent`"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Buttons -->
                <b-col md="6">
                  <b-row>
                    <!-- Add Button -->
                    <b-col class="mb-50">
                      <b-row class="justify-content-center">
                        <b-button
                          variant="primary"
                          class="m-1 mt-md-2"
                          @click="repeateAgain"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                          />
                          <span class="align-middle">Añadir nuevo</span>
                        </b-button>
                      </b-row>
                    </b-col>
                    <!-- Remove Button -->
                    <b-col
                      v-if="familyMembers.length>0"
                      class="mb-50"
                    >
                      <b-row class="justify-content-center">
                        <b-button
                          variant="outline-danger"
                          class="m-1 mt-md-2"
                          @click="removeItem(index)"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            class="mr-25"
                          />
                          <span class="align-middle">Eliminar</span>
                        </b-button>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
            <b-row
              v-if="familyMembers.length==0"
            >
              <b-col
                sm="12"
                md="3"
              >
                <!-- submit button -->
                <b-button
                  class="my-1"
                  variant="secondary"
                  block
                  type="button"

                  @click="repeateAgain"
                >
                  Agregar familiar
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row
          class="justify-content-center mt-4 mb-4"
        >
          <b-col
            sm="6"
            md="3"
          >
            <!-- return button -->
            <b-button
              class="my-1"
              variant="secondary"
              :to="{name:'PEP-data'}"
              block
            >
              Volver
            </b-button>
          </b-col>
          <b-col
            sm="6"
            md="3"
          >
            <!-- submit button -->
            <b-button
              class="my-1"
              variant="primary"
              block
              type="submit"
            >
              Guardar y avanzar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-col>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BForm, BFormGroup, BFormInput, BFormSelect, BFormRadioGroup, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
/* import useJwt from '@/auth/jwt/useJwt' */
import { required } from '@validations'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import { relationship } from '@/helpers/data'
import professionsOptions from '@utils/professions'
import activitiesOptions from '@utils/activities'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

/* eslint-disable */
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
/* eslint-enable */

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormRadioGroup,
    BButton,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    flatPickr,
  },
  mixins: [heightTransition],
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)
    const minDate = new Date(today)
    minDate.setFullYear(minDate.getFullYear() - 100)
    return {
      // Options values
      optionsTypeDocument: [
        { value: '', text: 'Seleccione una opción', disabled: true },
        { value: '1', text: 'DNI' },
        { value: '2', text: 'Carnét PNP' },
        { value: '3', text: 'Carnét FFAA' },
        { value: '4', text: 'Carnet Extranjeria' },
        { value: '6', text: 'RUC' },
        { value: '7', text: 'Pasaporte' },
      ],
      optionDependent: [
        { value: 'yes', text: 'Si' },
        { value: 'no', text: 'No' },
      ],
      required,
      maxDate,
      minDate,
      email: JSON.parse(localStorage.getItem('userData')).email,
      ruc: JSON.parse(localStorage.getItem('userData')).ruc,
      familyMembers: [],
      familyMembersIsComplete: '0', // 0: No --- 1: Si
      optionsFamiliarRelationship: relationship,
      optionsProfession: professionsOptions,
      optionsActivities: activitiesOptions,

      // Configs DatePickr
      configDatePickr: {
        allowInput: true,
        altInput: true,
        locale: Spanish,
        altFormat: 'd/m/Y',
      },
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    const dataU = JSON.parse(localStorage.getItem('userData'))
    const fmembers = dataU.familyData ? dataU.familyData.familyMembers : []

    if (fmembers.length > 0) {
      for (let i = 0; i < fmembers.length; i += 1) {
        this.familyMembers.push({
          familiarPLastname: fmembers[i].familiarPLastname,
          familiarMLastname: fmembers[i].familiarMLastname,
          familiarNames: fmembers[i].familiarNames,
          familiarDocumentType: fmembers[i].familiarDocumentType,
          familiarNumberDocument: fmembers[i].familiarNumberDocument,
          familiarRelationship: fmembers[i].familiarRelationship,
          familiarProfession: fmembers[i].familiarProfession,
          familiarOccupation: fmembers[i].familiarOccupation,
          familiarBornDate: fmembers[i].familiarBornDate,
          familiarIsDependent: fmembers[i].familiarIsDependent,
        })

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
        })
      }
    } else {
      this.repeateAgain()
    }

    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.familyMembers.push({
        familiarPLastname: '',
        familiarMLastname: '',
        familiarNames: '',
        familiarDocumentType: '',
        familiarNumberDocument: '',
        familiarRelationship: '',
        familiarProfession: {
          compuestoID: '00099',
          profesion: 'PROFESION U OCUPACION NO ESPECIFICADA',
        },
        familiarOccupation: {
          compuestoID: '000099',
          ocupacion: 'No declara',
        },
        familiarBornDate: '',
        familiarIsDependent: 'no',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    formatterNumber(value) {
      return value.replace(/[^0-9]/g, '')
    },
    removeItem(index) {
      this.familyMembers.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.row.scrollHeight)
      })
    },
    validationForm() {
      this.$refs.familyDataForm.validate().then(success => {
        if (success) {
          useJwt.familyData({
            ruc: this.ruc,
            email: this.email,
            familyData: this.familyMembers,
          })
            .then(({ data }) => {
              const user = data.user.value
              localStorage.setItem('userData', JSON.stringify(user))
              store.dispatch(
                'elementVerticalMenu/setStatusStep',
                { step: 'Family-data', status: user.familyData.completed },
                { root: true },
              )
              this.$router.replace('/registro/datos-socio-efide')
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Éxito',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Se ha guardado tu información con éxito.',
                      autoHideDelay: 5000,
                    },
                  })
                })
            })
            .catch(error => {
              console.log('errores', error)
              this.$refs.familyDataForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .repeater-form {
    overflow: hidden;
    transition: .35s height;
  }
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
