var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',[_c('validation-observer',{ref:"familyDataForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_vm._l((_vm.familyMembers),function(familyMember,index){return _c('b-card',{key:familyMember+index,ref:"row",refInFor:true,staticClass:"p-2",style:({height: _vm.trHeight}),attrs:{"familiar":familyMember+index}},[_c('b-row',{staticStyle:{"justify-content":"flex-end"}},[_c('b-col',{staticStyle:{"text-align":"center"},attrs:{"lg":"12"}},[_c('h4',{staticClass:"mt-1 mb-3"},[_vm._v(" DATOS DEL FAMILIAR N° "+_vm._s(index+1)+" ")])]),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Apellido paterno","label-for":(index + "familiarPLastname")}},[_c('validation-provider',{attrs:{"name":"Apellido Paterno","rules":"max:20","vid":(index + "familiarPLastname")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":(index + "familiarPLastname"),"state":errors.length > 0 ? false:null,"name":"familiarPLastname"},model:{value:(familyMember.familiarPLastname),callback:function ($$v) {_vm.$set(familyMember, "familiarPLastname", $$v)},expression:"familyMember.familiarPLastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Apellido materno","label-for":(index + "familiarMLastname")}},[_c('validation-provider',{attrs:{"name":"Apellido Materno","rules":"max:20","vid":(index + "familiarMLastname")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":(index + "familiarMLastname"),"state":errors.length > 0 ? false:null,"name":"familiarMLastname"},model:{value:(familyMember.familiarMLastname),callback:function ($$v) {_vm.$set(familyMember, "familiarMLastname", $$v)},expression:"familyMember.familiarMLastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nombres","label-for":(index + "familiarNames")}},[_c('validation-provider',{attrs:{"name":"Nombres","rules":"max:100","vid":(index + "familiarNames")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":(index + "familiarNames"),"state":errors.length > 0 ? false:null,"name":"familiarNames"},model:{value:(familyMember.familiarNames),callback:function ($$v) {_vm.$set(familyMember, "familiarNames", $$v)},expression:"familyMember.familiarNames"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Tipo de documento","label-for":(index + "familiarDocumentType")}},[_c('validation-provider',{attrs:{"name":"Tipo de documento","vid":(index + "familiarDocumentType")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":(index + "familiarDocumentType"),"options":_vm.optionsTypeDocument,"state":errors.length > 0 ? false:null,"name":"familiarDocumentType"},model:{value:(familyMember.familiarDocumentType),callback:function ($$v) {_vm.$set(familyMember, "familiarDocumentType", $$v)},expression:"familyMember.familiarDocumentType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Número de documento","label-for":(index + "familiarNumberDocument")}},[_c('validation-provider',{attrs:{"name":"Número de Documento","rules":("document:" + (familyMember.familiarDocumentType)),"vid":(index + "familiarNumberDocument")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":(index + "familiarNumberDocument"),"disabled":familyMember.familiarDocumentType ? false:true,"state":errors.length > 0 ? false:null,"name":"familiarNumberDocument"},model:{value:(familyMember.familiarNumberDocument),callback:function ($$v) {_vm.$set(familyMember, "familiarNumberDocument", $$v)},expression:"familyMember.familiarNumberDocument"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Parentesco","label-for":(index + "familiarRelationship")}},[_c('validation-provider',{attrs:{"name":"Parentesco","vid":(index + "familiarRelationship")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":(index + "familiarRelationship"),"options":_vm.optionsFamiliarRelationship,"state":errors.length > 0 ? false:null,"name":"familiarRelationship"},model:{value:(familyMember.familiarRelationship),callback:function ($$v) {_vm.$set(familyMember, "familiarRelationship", $$v)},expression:"familyMember.familiarRelationship"}})]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Profesión/Oficio","label-for":(index + "familiarProfession")}},[_c('v-select',{attrs:{"id":(index + "familiarProfession"),"label":"profesion","options":_vm.optionsProfession,"name":(index + "familiarProfession")},model:{value:(familyMember.familiarProfession),callback:function ($$v) {_vm.$set(familyMember, "familiarProfession", $$v)},expression:"familyMember.familiarProfession"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Lo sentimos, no hay opciones que coincidan. ")])])],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Ocupación","label-for":(index + "familiarOccupation")}},[_c('v-select',{attrs:{"id":(index + "familiarOccupation"),"label":"ocupacion","options":_vm.optionsActivities,"name":(index + "familiarOccupation")},model:{value:(familyMember.familiarOccupation),callback:function ($$v) {_vm.$set(familyMember, "familiarOccupation", $$v)},expression:"familyMember.familiarOccupation"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Lo sentimos, no hay opciones que coincidan. ")])])],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Fecha de nacimiento","label-for":(index + "familiarBornDate")}},[_c('validation-provider',{attrs:{"name":"Fecha de Nacimiento","vid":(index + "familiarBornDate")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{attrs:{"id":(index + "familiarBornDate"),"placeholder":"dd/mm/aaaa","name":(index + "familiarBornDate"),"state":errors.length > 0 ? false:null,"config":Object.assign({}, _vm.configDatePickr, {maxDate: _vm.maxDate, minDate: _vm.minDate})},model:{value:(familyMember.familiarBornDate),callback:function ($$v) {_vm.$set(familyMember, "familiarBornDate", $$v)},expression:"familyMember.familiarBornDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"my-2",attrs:{"md":"12"}},[_c('b-form-group',{staticStyle:{"text-align":"center"},attrs:{"label-cols-sm":"12","content-cols-sm":"12","label":"¿El familiar es dependiente?","label-for":(index + "isDependent")}},[_c('validation-provider',{attrs:{"name":"Es dependiente","vid":(index + "isDependent")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"id":(index + "isDependent"),"options":_vm.optionDependent,"value-field":"value","text-field":"text","name":(index + "isDependent")},model:{value:(familyMember.familiarIsDependent),callback:function ($$v) {_vm.$set(familyMember, "familiarIsDependent", $$v)},expression:"familyMember.familiarIsDependent"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{staticClass:"mb-50"},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-button',{staticClass:"m-1 mt-md-2",attrs:{"variant":"primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Añadir nuevo")])],1)],1)],1),(_vm.familyMembers.length>0)?_c('b-col',{staticClass:"mb-50"},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-button',{staticClass:"m-1 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"Trash2Icon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Eliminar")])],1)],1)],1):_vm._e()],1)],1)],1)],1)}),(_vm.familyMembers.length==0)?_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"3"}},[_c('b-button',{staticClass:"my-1",attrs:{"variant":"secondary","block":"","type":"button"},on:{"click":_vm.repeateAgain}},[_vm._v(" Agregar familiar ")])],1)],1):_vm._e()],2)],1),_c('b-row',{staticClass:"justify-content-center mt-4 mb-4"},[_c('b-col',{attrs:{"sm":"6","md":"3"}},[_c('b-button',{staticClass:"my-1",attrs:{"variant":"secondary","to":{name:'PEP-data'},"block":""}},[_vm._v(" Volver ")])],1),_c('b-col',{attrs:{"sm":"6","md":"3"}},[_c('b-button',{staticClass:"my-1",attrs:{"variant":"primary","block":"","type":"submit"}},[_vm._v(" Guardar y avanzar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }